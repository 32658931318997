// Import htmx and extensions
import htmx from "htmx.org/dist/htmx.esm";
import "htmx-ext-alpine-morph";
import "htmx-ext-ws";
import "htmx-ext-debug";
import "htmx-ext-class-tools";
import "htmx-ext-loading-states";
import hyperscript from "hyperscript.org";

// Make htmx globally available
window.htmx = htmx;

// Initialize all extensions after Alpine is ready
document.addEventListener("alpine:init", () => {
  // Initialize with Alpine's morph plugin
  htmx.defineExtension("alpine-morph", {
    morph: (target, content) => {
      Alpine.morph(target, content);
    },
  });
});

// Initialize hyperscript
window._hyperscript = hyperscript;
hyperscript.browserInit();

// Uncomment below code snippet to enable htmx logging
// window.htmx.logger = function (elt, event, data) {
//   if (console) {
//     console.log(event, elt, data);
//   }
// };

document.addEventListener("htmx:afterRequest", function (event) {
  // Add null check for xhr
  if (event.detail.xhr) {
    const hxRedirect = event.detail.xhr.getResponseHeader("hx-redirect");
    if (
      hxRedirect &&
      hxRedirect === window.location.pathname + window.location.hash
    ) {
      window.location.reload();
    }
  }
});

// Initialize Flowbite after DOM content and htmx updates
const initFlowbiteIfReady = () => {
  if (
    typeof initFlowbite === "function" &&
    document.readyState === "complete"
  ) {
    try {
      initFlowbite();
    } catch (e) {
      console.warn("Flowbite initialization failed:", e);
    }
  }
};

document.addEventListener("DOMContentLoaded", initFlowbiteIfReady);
document.addEventListener("readystatechange", initFlowbiteIfReady);
htmx.on("htmx:afterSettle", initFlowbiteIfReady);
