window.Swal = require("sweetalert2");

// confirmDialog.js
function confirmDeleteDialog(text) {
  return Swal.fire({
    title: "Confirm",
    text: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
    customClass: {
      confirmButton: "confirm-delete-button",
      cancelButton: "confirm-delete-cancel-button",
    },
  });
}
window.confirmDeleteDialog = confirmDeleteDialog;

function analystConfirmAssignDialog(text) {
  return Swal.fire({
    title: "Confirm",
    text: text,
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Yes, assign!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
    customClass: {
      confirmButton: "confirm-delete-button",
      cancelButton: "confirm-delete-cancel-button",
    },
  });
}
window.analystConfirmAssignDialog = analystConfirmAssignDialog;

function analystConfirmRemoveDialog(text) {
  return Swal.fire({
    title: "Confirm",
    text: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, remove!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
    customClass: {
      confirmButton: "confirm-delete-button",
      cancelButton: "confirm-delete-cancel-button",
    },
  });
}
window.analystConfirmRemoveDialog = analystConfirmRemoveDialog;

function watchlistConfirmDialog(text) {
  return Swal.fire({
    title: "Confirm",
    text: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, remove the company",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
    customClass: {
      confirmButton: "confirm-delete-button",
      cancelButton: "confirm-delete-cancel-button",
    },
  });
}
window.watchlistConfirmDialog = watchlistConfirmDialog;

function showToast(message, levelTag) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    showCloseButton: true,
    customClass: {
      container: "toast-container",
      popup: "toast-popup",
      title: "toast-title",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: levelTag,
    title: message,
  });
}

window.showToast = showToast;
