// Import Alpine and plugins first
import Alpine from "alpinejs";
import Morph from "@alpinejs/morph";
window.Alpine = Alpine;
Alpine.plugin(Morph);

// Import htmx setup before other modules
import "./htmx/htmx.js";

// Import styles
import "../css/app.css";
import "../css/company_tracking_status.css";
import "../css/notes.css";
import "../css/tabulator_simple.demin.css";

// Import and initialize Flowbite
import * as flowbite from "flowbite";
window.initFlowbite = flowbite.initFlowbite;

// Import other modules
import "./sidebar";
import "./dark-mode";
import "./sweetalert2";
import "./screener.js";
import "./hijack.js";
import "./helper_utils.js";

// Import chat module last since it depends on other modules
import "./chatbots/chat.js";

// Start Alpine after everything is loaded
document.addEventListener("DOMContentLoaded", () => {
  Alpine.start();
});
