const httpDelete = (url, csrf_token, callback, err = console.error) => {
  fetch(url, {
    method: "DELETE",
    headers: {
      "X-CSRFToken": csrf_token,
    },
  })
    .then((response) => response.text())
    .then((response) => callback(response))
    .catch((error) => err(error));
};
window.httpDelete = httpDelete;

String.prototype.toSafeId = function () {
  return encodeURIComponent(this)
    .toLowerCase()
    .replace(/\.|%[0-9a-z]{2}/gi, "")
    .replace(/[\(\)'"]/g, "_");
};

document.addEventListener("DOMContentLoaded", () => {
  // Find all tab lists on the page
  const tabLists = document.querySelectorAll("[data-tabs-toggle]");

  tabLists.forEach((tabList) => {
    // Get the target content container ID from data-tabs-toggle
    const tabsToggleId = tabList.getAttribute("data-tabs-toggle");

    // Get the tabs element (parent ul)
    const tabsEl = tabList;

    // Get all tabs in this list
    const tabs = tabList.querySelectorAll('[role="tab"]');

    // Create tabElements array for this tab list
    const tabElements = Array.from(tabs).map((tab) => ({
      id: tab.getAttribute("data-tabs-target").substring(1), // Remove the # from data-tabs-target
      triggerEl: tab,
      targetEl: document.querySelector(tab.getAttribute("data-tabs-target")),
    }));

    // Initialize Tabs with tabsEl
    const tabInstance = new Tabs(tabsEl, tabElements);

    // Add click listeners
    tabElements.forEach((tab) => {
      tab.triggerEl.addEventListener("click", (event) => {
        event.preventDefault();
        const href = tab.triggerEl.getAttribute("href");
        if (href && href.startsWith("#")) {
          // Only update URL if this tab's href matches the current URL's pathname
          const tabsToggleId = tabList.getAttribute("data-tabs-toggle");
          const targetEl = document.querySelector(tabsToggleId);
          if (targetEl && targetEl.contains(document.querySelector(href))) {
            // Update URL without jumping
            history.pushState(null, "", href);
          }
        }
      });
    });

    // Check for hash on page load
    if (window.location.hash) {
      const tabId = window.location.hash.substring(1);
      const targetEl = document.querySelector(`#${tabId}`);
      // Only activate tab if the target element is within this tab instance's container
      if (
        targetEl &&
        document.querySelector(tabsToggleId).contains(targetEl) &&
        tabInstance.getTab(tabId)
      ) {
        tabInstance.show(tabId);
      }
    }
  });
});

// Check if user is hijacked and change navbar color
document.addEventListener("DOMContentLoaded", function () {
  const navbar = document.getElementById("main-navbar");
  const hijackNotification = document.getElementById("djhj");
  if (hijackNotification) {
    navbar.classList.add("navbar-hijacked");
    navbar.classList.remove("bg-white", "dark:bg-gray-800");
  }

  // Add global htmx:afterSettle event listener
  htmx.on("htmx:afterSettle", function (event) {
    if (typeof initFlowbite === "function") {
      initFlowbite();
    }
  });
});
