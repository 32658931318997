/**
 * @typedef {Object} Window
 * @property {Object} hljs - The highlight.js library for syntax highlighting
 * @property {function} hljs.highlightElement - Function to highlight a DOM element
 * @property {function} hljs.highlight - Function to highlight a string of code
 * @property {function} hljs.getLanguage - Function to check if a language is supported
 * @property {Object} md - The markdown-it instance created in this file
 * @property {Object} TEMPERATURE_SETTINGS - Global temperature settings for the chatbot
 * @property {number} TEMPERATURE_SETTINGS.MIN - Minimum temperature value
 * @property {number} TEMPERATURE_SETTINGS.MAX - Maximum temperature value
 * @property {number} TEMPERATURE_SETTINGS.DEFAULT - Default temperature value
 * @property {Object} modelTempLimits - Model-specific temperature limits
 * @property {number} modelTempLimits.min - Minimum temperature for the current model
 * @property {number} modelTempLimits.max - Maximum temperature for the current model
 * @property {string} chatUsername - Current user's display name
 */

/**
 * @typedef {Object} ChatMessagesStore
 * @property {Array<Message>} messages - Array of chat messages
 * @property {boolean} isProcessing - Whether a message is currently being processed/generated
 * @property {string} currentResponse - The current streaming AI response text
 * @property {Function} addUserMessage - Add a user message to the store
 * @property {Function} updateAIResponse - Update AI response in the store
 * @property {Function} handleError - Handle error messages
 * @property {Function} attemptRecovery - Attempt to recover from errors
 */

/**
 * @typedef {Object} Message
 * @property {string} type - MESSAGE_TYPES value
 * @property {string} content - Message content (HTML or markdown)
 * @property {boolean} [isComplete] - Whether the message is complete
 * @property {string} [timestamp] - ISO timestamp when message was created
 * @property {Object} [details] - Additional message details for errors
 * @property {number} [code] - Error code for error messages
 * @property {boolean} [recoverable] - Whether the error is recoverable
 */

/**
 * @typedef {(
 *   "WEBSOCKET_CONNECTION"|
 *   "WEBSOCKET_MESSAGE"|
 *   "MESSAGE_SUBMISSION"|
 *   "MARKDOWN_RENDERING"|
 *   "CHAT_STORE"|
 *   "CHAT_STATE"|
 *   "FORM_VALIDATION"|
 *   "ELEMENT_NOT_FOUND"|
 *   "SERVER_ERROR"|
 *   "UNKNOWN_ERROR"
 * )} ChatErrorType
 */

/**
 * @typedef {Object} ProviderModel
 * @property {string} provider - Provider name (e.g. "fireworks")
 * @property {string} model - Model name (e.g. "llama-v3p1-405b-instruct")
 */

/**
 * @typedef {HTMLElement & {selectionStart: number, selectionEnd: number}} TextareaElement
 * @typedef {HTMLElement & {scrollTop: number, scrollHeight: number}} ScrollableElement
 */

/**
 * @typedef {Object} WsPayload
 * @property {string} type - WS_EVENT_TYPES value
 * @property {string} [message] - Message content
 * @property {Object} [details] - Additional details
 * @property {string} [chat_uuid] - Chat session UUID
 * @property {string} [role] - Message role (human/ai)
 * @property {string} [content] - Message content
 */

/**
 * @typedef {Object} TemperaturePreset
 * @property {number} value - Temperature value
 * @property {string} label - Display label
 * @property {string} tooltip - Tooltip text
 */

// ===================================================
// IMPORTS
// ===================================================
import MarkdownIt from "markdown-it";
// highlight.js is loaded globally and available as window.hljs

// ===================================================
// CONSTANTS
// ===================================================
/**
 * Message type constants for chat application
 * @namespace MESSAGE_TYPES
 * @type {Object.<string, string>}
 */
const MESSAGE_TYPES = {
  USER: "user",
  AI: "ai",
  ERROR: "error",
  SYSTEM: "system",
};

/**
 * WebSocket event type constants for chat application
 * @namespace WS_EVENT_TYPES
 * @type {Object.<string, string>}
 */
const WS_EVENT_TYPES = {
  AI_CHUNK: "ai_chunk",
  AI_MESSAGE_END: "ai_message_end",
  ERROR: "error",
  HISTORY_MESSAGE: "history_message",
  HISTORY_COMPLETE: "history_complete",
  SESSION_CREATED: "session_created",
  SESSION_UPDATED: "session_updated",
  STATUS: "status",
};

// ===================================================
// HELPER FUNCTIONS
// ===================================================
/**
 * Throttle helper to limit how often a function can be called
 * @function throttle
 * @param {Function} func - Function to throttle
 * @param {number} limit - Limit in milliseconds
 * @returns {Function} Throttled function
 */
const throttle = (func, limit) => {
  let inThrottle;
  return function throttledFunction(...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

/**
 * Memoize helper to cache expensive function results with LRU eviction and TTL
 * @function memoize
 * @param {Function} func - Function to memoize
 * @param {Object} [options] - Memoization options
 * @param {number} [options.maxSize=100] - Maximum number of entries to keep in cache
 * @param {number} [options.ttl=300000] - Time-to-live in milliseconds (5 minutes default)
 * @returns {Function} Memoized function with memory-safe caching
 */
const memoize = (func, { maxSize = 100, ttl = 300000 } = {}) => {
  const cache = new Map();
  const lruKeys = [];
  const timestamps = new Map();

  // For monitoring cache effectiveness (development only)
  let hits = 0,
    misses = 0;

  return function memoizedFunction(...args) {
    // Create a cache key from the arguments
    const key = JSON.stringify(args);
    const now = Date.now();

    // Check for expired entries
    if (cache.has(key)) {
      const timestamp = timestamps.get(key) || 0;
      if (now - timestamp > ttl) {
        // Entry expired, remove it
        cache.delete(key);
        timestamps.delete(key);
        lruKeys.splice(lruKeys.indexOf(key), 1);
      }
    }

    // Cache hit
    if (cache.has(key)) {
      hits++;
      // Update LRU order (move to end of array)
      const keyIndex = lruKeys.indexOf(key);
      if (keyIndex !== -1) {
        lruKeys.splice(keyIndex, 1);
        lruKeys.push(key);
      }
      return cache.get(key);
    }

    // Cache miss
    misses++;
    const result = func(...args);

    // Store in cache
    cache.set(key, result);
    timestamps.set(key, now);
    lruKeys.push(key);

    // Enforce maximum size by removing least recently used items
    while (cache.size > maxSize) {
      const oldestKey = lruKeys.shift();
      cache.delete(oldestKey);
      timestamps.delete(oldestKey);
    }

    // Log cache stats in development (every 100 calls)
    if ((hits + misses) % 100 === 0 && process.env.NODE_ENV === "development") {
      console.debug(
        `Memoize cache stats for ${func.name}: ${hits} hits, ${misses} misses, hit rate: ${((hits / (hits + misses)) * 100).toFixed(1)}%`,
      );
    }

    return result;
  };
};

/**
 * Temperature management helper functions
 * @namespace TemperatureHelper
 */
const TemperatureHelper = {
  /**
   * Clamp temperature value between specified limits
   * @function clampTemperature
   * @param {number} value - Temperature value to clamp
   * @param {number} min - Minimum temperature value
   * @param {number} max - Maximum temperature value
   * @returns {number} Clamped temperature value between min and max
   */
  clampTemperature(value, min, max) {
    return Math.min(Math.max(parseFloat(value), min), max);
  },

  /**
   * Get the temperature limits from global settings or provided values
   * @function getTemperatureLimits
   * @returns {{
   *   min: number,
   *   max: number,
   *   default: number
   * }} Object containing min, max and default temperature values
   */
  getTemperatureLimits() {
    return {
      min: window.modelTempLimits?.min ?? window.TEMPERATURE_SETTINGS.MIN,
      max: window.modelTempLimits?.max ?? window.TEMPERATURE_SETTINGS.MAX,
      default: window.TEMPERATURE_SETTINGS?.DEFAULT || 0.6,
    };
  },

  /**
   * Get display label for a temperature value
   * @function getLabel
   * @param {number} temperature - Temperature value
   * @param {Object.<number, string>} [labels={}] - Map of temperature values to display labels
   * @returns {string} Display label for temperature
   */
  getLabel(temperature, labels = {}) {
    return labels[temperature] || `${temperature}`;
  },

  /**
   * Get tooltip text for a temperature value
   * @function getTooltip
   * @param {number} temperature - Temperature value
   * @param {Object.<number, string>} tooltips - Map of temperature values to tooltip text
   * @returns {string} Tooltip text for temperature
   */
  getTooltip(temperature, tooltips = {}) {
    return tooltips[temperature] || `Temperature: ${temperature}`;
  },
};

// ===================================================
// UTILITY FUNCTIONS
// ===================================================
/**
 * Update accessibility status message for screen readers
 * @param {string} message - The status message to display
 * @param {boolean} [showVisualFeedback=false] - Whether to show visual feedback
 * @returns {void} This function doesn't return a value
 */
const updateA11yStatus = (message, showVisualFeedback = false) => {
  const statusEl = document.getElementById("a11y-status");
  if (statusEl) {
    statusEl.textContent = message;
    setTimeout(() => (statusEl.textContent = ""), 2000);
  }

  // Show visual feedback if requested
  if (showVisualFeedback) {
    const feedbackEl = document.createElement("div");
    feedbackEl.className =
      "fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in-out";
    feedbackEl.textContent = message;
    document.body.appendChild(feedbackEl);

    // Remove after animation
    setTimeout(() => {
      feedbackEl.classList.add("opacity-0");
      setTimeout(() => {
        document.body.removeChild(feedbackEl);
      }, 500);
    }, 1500);
  }
};

/**
 * Error types and codes for chat application
 * @namespace ChatErrorTypes
 * @type {Object.<string, {code: number, recoverable: boolean, message: string}>}
 */
const ChatErrorTypes = {
  WEBSOCKET_CONNECTION: {
    code: 1001,
    recoverable: true,
    message: "WebSocket connection error",
  },
  WEBSOCKET_MESSAGE: {
    code: 1002,
    recoverable: true,
    message: "WebSocket message processing error",
  },
  MESSAGE_SUBMISSION: {
    code: 1003,
    recoverable: true,
    message: "Message submission error",
  },
  MARKDOWN_RENDERING: {
    code: 2001,
    recoverable: false,
    message: "Markdown rendering error",
  },
  CHAT_STORE: { code: 3001, recoverable: true, message: "Chat store error" },
  CHAT_STATE: { code: 3002, recoverable: true, message: "Chat state error" },
  FORM_VALIDATION: {
    code: 4001,
    recoverable: true,
    message: "Form validation error",
  },
  ELEMENT_NOT_FOUND: {
    code: 4002,
    recoverable: false,
    message: "Required element not found",
  },
  SERVER_ERROR: { code: 5001, recoverable: false, message: "Server error" },
  UNKNOWN_ERROR: { code: 9999, recoverable: false, message: "Unknown error" },
};

/**
 * Handle and log errors with accessibility notifications
 * @param {string} context - Context where error occurred
 * @param {Error|string} error - JavaScript Error object or error message
 * @param {string} [errorType="UNKNOWN_ERROR"] - Error type key from ChatErrorTypes
 * @returns {Error} The original or created error object
 */
const handleError = (context, error, errorType = "UNKNOWN_ERROR") => {
  // Convert string errors to Error objects
  const errorObj = typeof error === "string" ? new Error(error) : error;
  const errorInfo = ChatErrorTypes[errorType] || ChatErrorTypes.UNKNOWN_ERROR;

  // Add error code to error object if not already present
  errorObj.code = errorObj.code || errorInfo.code;
  errorObj.recoverable =
    errorObj.recoverable !== undefined
      ? errorObj.recoverable
      : errorInfo.recoverable;

  // Create a detailed message for logging
  const detailedMsg = `[Error ${errorObj.code}] ${context}: ${errorObj.message}`;
  console.error(detailedMsg, errorObj);

  // Simplified message for screen readers
  updateA11yStatus(`Error ${errorObj.code} in ${context}: ${errorObj.message}`);

  // Attempt recovery for recoverable errors
  if (errorObj.recoverable) {
    // Let the store handle recovery
    const chatMessages = Alpine.store("chatMessages");
    if (chatMessages && typeof chatMessages.attemptRecovery === "function") {
      chatMessages.attemptRecovery(errorObj);
    }
  }

  return errorObj;
};

// ===================================================
// UTILITY FUNCTIONS
// ===================================================
/**
 * Notify listeners that messages have been updated by dispatching a custom event
 * @returns {void} This function doesn't return a value
 */
const notifyMessagesUpdated = () => {
  document.dispatchEvent(
    new CustomEvent("chat-messages-updated", {
      bubbles: true,
      detail: {},
    }),
  );

  // Add this direct scroll trigger as fallback
  const chatBox = document.getElementById("chat-box");
  if (chatBox) {
    setTimeout(() => {
      chatBox.scrollTop = chatBox.scrollHeight;
    }, 100);
  }

  // Initialize tooltips after messages are updated
  // This ensures tooltips work on newly added message elements
  setTimeout(() => {
    initTooltips();
  }, 100); // Short delay to ensure DOM is fully updated
};

/**
 * Render markdown content with syntax highlighting
 * @param {string} content - Markdown content to render
 * @param {boolean} [isStreaming=false] - Whether content is being streamed in real-time
 * @returns {string} Rendered HTML content with syntax highlighting applied
 * @throws {Error} Throws an error if rendering fails
 */
const originalRenderMarkdownWithHighlighting = (
  content,
  isStreaming = false,
) => {
  try {
    // First sanitize the content to ensure it's safe
    const sanitized = TextFormatHelper.sanitizeMarkdown(content);
    // Then render the sanitized content
    const rendered = window.md.render(sanitized);

    // Initialize syntax highlighting for new content
    if (!isStreaming) {
      // Use requestAnimationFrame to ensure DOM is updated
      requestAnimationFrame(() => {
        if (window.hljs) {
          document.querySelectorAll("pre code").forEach((block) => {
            // Only apply highlighting to blocks that haven't been highlighted by markdown-it
            if (
              !block.classList.contains("hljs") &&
              !block.innerHTML.includes("hljs-")
            ) {
              try {
                window.hljs.highlightElement(block);
                // Ensure the code block has the hljs class for styling
                block.classList.add("hljs");
                // Also ensure the parent pre has the hljs class
                if (
                  block.parentElement &&
                  !block.parentElement.classList.contains("hljs")
                ) {
                  block.parentElement.classList.add("hljs");
                }
              } catch (error) {
                console.error("Highlight error:", error);
                // If highlighting fails, ensure content is still safe
                block.innerHTML = window.md.utils.escapeHtml(block.textContent);
                block.classList.add("hljs");
              }
            }
          });
        }
      });
    }
    return rendered;
  } catch (error) {
    handleError("rendering markdown", error, "MARKDOWN_RENDERING");
    // Return safely escaped content on error
    return TextFormatHelper.escapeHtmlCharacters(content);
  }
};

const renderMarkdownWithHighlighting = memoize(
  originalRenderMarkdownWithHighlighting,
  {
    maxSize: 200, // Keep last 200 rendered messages
    ttl: 300000, // 5 minute cache (300,000ms)
  },
);

// ===================================================
// MARKDOWN CONFIGURATION
// ===================================================
// Create markdown instance and make it globally available
/**
 * Markdown parser configuration with syntax highlighting support
 * @type {MarkdownIt}
 */
window.md = new MarkdownIt({
  html: false, // CRITICAL: Must remain false for security - prevents HTML injection
  xhtmlOut: true,
  breaks: true,
  linkify: true,
  typographer: true,
  /**
   * Syntax highlighting function for MarkdownIt
   * @param {string} str - The code string to highlight
   * @param {string} lang - Programming language identifier
   * @returns {string} Highlighted HTML or empty string if language not supported
   */
  highlight: function (str, lang) {
    if (window.hljs && lang && window.hljs.getLanguage(lang)) {
      try {
        return window.hljs.highlight(str, {
          language: lang,
          ignoreIllegals: true,
        }).value;
      } catch (error) {
        console.error("Highlight error:", error);
        return window.md.utils.escapeHtml(str);
      }
    }
    // For unknown languages, still apply basic hljs class for styling consistency
    return '<span class="hljs">' + window.md.utils.escapeHtml(str) + "</span>";
  },
});

// Override the default fence renderer to properly wrap code blocks with enhanced security
/**
 * Custom code block renderer for MarkdownIt
 * @type {import('markdown-it').TokenRender}
 * @param {Array<import('markdown-it/lib/token')>} tokens - Markdown tokens
 * @param {number} idx - Token index
 * @param {import('markdown-it').Options} options - Renderer options
 * @param {Object} env - Markdown environment
 * @param {import('markdown-it').Renderer} slf - Default renderer object
 * @returns {string} Rendered HTML for code blocks
 */
window.md.renderer.rules.fence = function (tokens, idx, _options, _env, _slf) {
  const token = tokens[idx];
  const lang = token.info ? window.md.utils.escapeHtml(token.info.trim()) : "";
  const highlighted = window.md.options.highlight(token.content, lang, "");

  // Create secure code block with highlighted content
  return `<pre class="hljs"><code class="language-${lang} hljs">${highlighted}</code></pre>`;
};

// Enable all markdown features
window.md.enable([
  "heading",
  "emphasis",
  "strikethrough",
  "blockquote",
  "list",
  "backticks",
  "code",
  "fence",
  "table",
  "link",
  "image",
  "hr",
]);

// ===================================================
// GLOBAL VARIABLES
// ===================================================
// Get username from window (set in template)
let username = "";
if (typeof window !== "undefined") {
  username = window.chatUsername || "";
}

// ===================================================
// EVENT LISTENERS
// ===================================================
/**
 * Listen for title-updated events from the server
 * @event title-updated
 * @listens window#title-updated
 */
window.addEventListener("title-updated", function (event) {
  if (event.detail && event.detail.newTitle) {
    updateChatSessionTitle(event.detail.newTitle);
  }
});

/**
 * Initialize model selection and temperature controls
 * @event DOMContentLoaded
 * @listens document#DOMContentLoaded
 */
document.addEventListener("DOMContentLoaded", function () {
  // Listen for title-updated events from the server
  window.addEventListener("title-updated", function (event) {
    if (event.detail && event.detail.newTitle) {
      updateChatSessionTitle(event.detail.newTitle);
    }
  });

  const modelSelector = document.getElementById("model-selector");
  const modelInput = document.getElementById("model-input");

  // Handle model changes
  modelSelector?.addEventListener("change", () => {
    if (modelInput) {
      modelInput.value = modelSelector.value;
    }
  });

  // Initialize with active chat's model if available
  if (modelSelector && modelInput) {
    const pathSegments = window.location.pathname.split("/");
    const isChatSession =
      pathSegments.includes("chat") &&
      pathSegments[pathSegments.length - 2] !== "chat";

    if (isChatSession && modelSelector.options.length > 0) {
      // Value will be set from template's selected attribute
      modelInput.value = modelSelector.value;
    } else {
      // Default initialization
      modelInput.value = modelSelector.value;
    }
  }
});

/**
 * Handle incoming WebSocket messages
 * @event htmx:wsAfterMessage
 * @listens document.body#htmx:wsAfterMessage
 * @param {CustomEvent} event - HTMX WebSocket message event
 * @description Processes different types of WebSocket messages:
 *  - ai_chunk: Partial AI response during streaming
 *  - ai_message_end: Final AI response when complete
 *  - error: Error message from server
 *  - history_message: Message from chat history
 *  - history_complete: Signal that all history has been sent
 *  - session_created: New chat session created
 *  - session_updated: Chat session updated
 *  - status: Informational status message
 */
document.body.addEventListener("htmx:wsAfterMessage", function (event) {
  try {
    /** @type {WsPayload} */
    const wsPayload = JSON.parse(event.detail.message);
    /** @type {ChatMessagesStore} */
    const chatMessages = Alpine.store("chatMessages");

    if (!chatMessages) {
      handleError(
        "accessing chat messages store",
        "Chat messages store not initialized",
        "CHAT_STORE",
      );
      return;
    }

    switch (wsPayload.type) {
      case WS_EVENT_TYPES.AI_CHUNK:
        chatMessages.updateAIResponse(wsPayload.message);
        break;

      case WS_EVENT_TYPES.AI_MESSAGE_END:
        chatMessages.updateAIResponse(wsPayload.message, true);
        break;

      case WS_EVENT_TYPES.ERROR:
        // Enhanced error handling with server-provided details
        const errorDetails = wsPayload.details || {};
        const errorCode = errorDetails.code || ChatErrorTypes.SERVER_ERROR.code;
        const errorMessage = `${wsPayload.message}${errorDetails.trace ? ": " + errorDetails.trace : ""}`;

        chatMessages.handleError({
          message: errorMessage,
          code: errorCode,
          details: errorDetails,
          recoverable:
            errorDetails.recoverable !== undefined
              ? errorDetails.recoverable
              : false,
        });
        break;

      case WS_EVENT_TYPES.HISTORY_MESSAGE:
        // Check if message already exists to avoid duplicates
        // Apply double sanitization for defense in depth
        const sanitizedContent = TextFormatHelper.sanitizeMarkdown(
          wsPayload.content,
        );
        // Render the sanitized content
        const renderedContent =
          renderMarkdownWithHighlighting(sanitizedContent);

        const exists = chatMessages.messages.some(
          (msg) =>
            msg.content === renderedContent &&
            msg.type ===
              (wsPayload.role === "human"
                ? MESSAGE_TYPES.USER
                : MESSAGE_TYPES.AI),
        );

        if (!exists) {
          chatMessages.messages.push({
            type:
              wsPayload.role === "human"
                ? MESSAGE_TYPES.USER
                : MESSAGE_TYPES.AI,
            content: renderedContent,
            rawContent: sanitizedContent, // Store sanitized markdown for safety
            isComplete: true,
            timestamp: new Date().toISOString(),
          });

          // Update UI for newly added messages
          notifyMessagesUpdated();
        }
        break;

      case WS_EVENT_TYPES.HISTORY_COMPLETE:
        chatMessages.isProcessing = false;
        // Notify that history is complete to ensure UI is updated
        notifyMessagesUpdated();
        break;

      case WS_EVENT_TYPES.SESSION_CREATED:
        const newUrl = `/chatbot/chat/${wsPayload.chat_uuid}/`;
        window.history.replaceState(
          { chatUuid: wsPayload.chat_uuid },
          "",
          newUrl,
        );

        // Update the chat_uuid in the rename and delete forms
        document
          .querySelectorAll('input[name="chat_uuid"]')
          .forEach((input) => {
            input.value = wsPayload.chat_uuid;
          });

        // Store the chat UUID in Alpine.js store for future reference
        try {
          const chatState = Alpine.store("chatState");
          if (chatState) {
            chatState.chatUuid = wsPayload.chat_uuid;

            // Notify Alpine.js components that chat UUID has been updated
            window.dispatchEvent(
              new CustomEvent("chat-uuid-updated", {
                detail: { uuid: wsPayload.chat_uuid },
              }),
            );
          }
        } catch (e) {
          console.error("Error updating chat UUID in Alpine store:", e);
        }

        // Update history drawer
        htmx.ajax("GET", "/chatbot/history/", {
          target: "#chat-history-list",
          swap: "innerHTML",
        });
        break;

      case WS_EVENT_TYPES.SESSION_UPDATED:
        // Update the session title in the UI
        if (wsPayload.title) {
          updateChatSessionTitle(wsPayload.title);
        }
        break;

      case WS_EVENT_TYPES.STATUS:
        // Status messages are informational only and don't require action
        console.log("WebSocket status update:", wsPayload.message);
        break;

      default:
        handleError(
          "processing WebSocket message",
          `Unknown WebSocket message type: ${wsPayload.type}`,
          "WEBSOCKET_MESSAGE",
        );
    }
  } catch (error) {
    handleError("processing WebSocket message", error, "WEBSOCKET_MESSAGE");
  }
});

document.body.addEventListener("htmx:wsConnectError", function (event) {
  console.error("WebSocket connection error:", event);
  // Try to recover automatically with a page refresh if this is not a result of a refresh already
  if (!window.webSocketReconnectAttempted) {
    window.webSocketReconnectAttempted = true;
    // Attempt to reconnect after a short delay
    setTimeout(() => {
      console.log("Attempting to reconnect WebSocket...");
      const wsElement = document.querySelector("[ws-connect]");
      if (wsElement) {
        htmx.trigger(wsElement, "reconnect", {});
      }
    }, 1000);
  }

  Alpine.store("chatMessages")?.handleError({
    message: "Failed to connect to chat server. Please refresh the page.",
    code: ChatErrorTypes.WEBSOCKET_CONNECTION.code,
    recoverable: true,
  });
});

// Add new event listener for WebSocket connection
document.body.addEventListener("htmx:wsConnected", function (event) {
  console.log("WebSocket connected successfully:", event);
  window.webSocketReconnectAttempted = false;
});

/**
 * Update the chat session title in the UI
 * @param {string} title - The new title for the chat session
 * @returns {void}
 */
function updateChatSessionTitle(title) {
  // Update page title
  const originalPageTitle = document.title;
  const newPageTitle = originalPageTitle.includes("-")
    ? originalPageTitle.replace(/^.*?-/, `${title} -`)
    : `${title} - ${originalPageTitle}`;
  document.title = newPageTitle;

  // Update the chat title in the header (multiple possible elements)
  const chatTitleElement = document.getElementById("chat-title");
  if (chatTitleElement) {
    chatTitleElement.textContent = title;
  }

  // Also update the title in the dropdown button if present
  const chatTitleDropdown = document.getElementById("chat-title-dropdown");
  if (chatTitleDropdown) {
    // The text might be in a span within the button
    const titleSpan = chatTitleDropdown.querySelector("span");
    if (titleSpan) {
      titleSpan.textContent = title;
    } else if (
      chatTitleDropdown.textContent.trim() &&
      !chatTitleDropdown.querySelector("svg")
    ) {
      chatTitleDropdown.textContent = title;
    }
  }

  // Update Alpine.js store if available
  try {
    const chatState = Alpine.store("chatState");
    if (chatState) {
      chatState.chatTitle = title;
    }
  } catch (e) {
    console.error("Error updating chat title in Alpine store:", e);
  }

  // Update drawer items if the current chat is in the history drawer
  const currentPathUuid = window.location.pathname
    .split("/")
    .filter(Boolean)
    .pop();
  if (currentPathUuid) {
    const chatHistoryItem = document.querySelector(
      `[data-chat-id="${currentPathUuid}"]`,
    );
    if (chatHistoryItem) {
      const titleElement = chatHistoryItem.querySelector(".font-medium");
      if (titleElement) {
        titleElement.textContent = title;
      }
    }
  }

  // Dispatch an event to notify other components of the title change
  window.dispatchEvent(
    new CustomEvent("chat-title-changed", {
      detail: { title },
    }),
  );
}

/**
 * Initialize or reinitialize Flowbite tooltips
 * @function initTooltips
 * @returns {void} This function doesn't return a value
 */
function initTooltips() {
  // Try different ways to access Flowbite's initialization API
  if (typeof window.Flowbite !== "undefined") {
    // Newer Flowbite version
    if (typeof window.Flowbite.initTooltips === "function") {
      window.Flowbite.initTooltips();
    } else if (typeof window.Flowbite.init === "function") {
      window.Flowbite.init();
    }
  } else if (typeof window.initFlowbite === "function") {
    // Older Flowbite version
    window.initFlowbite();
  } else {
    // Fallback: manually initialize tooltips
    document.querySelectorAll("[data-tooltip-target]").forEach((triggerEl) => {
      const targetId = triggerEl.getAttribute("data-tooltip-target");
      const tooltipEl = document.getElementById(targetId);

      if (tooltipEl) {
        // Remove any existing event listeners to prevent duplicates
        const newTriggerEl = triggerEl.cloneNode(true);
        triggerEl.parentNode.replaceChild(newTriggerEl, triggerEl);

        // Add event listeners to show/hide tooltip
        newTriggerEl.addEventListener("mouseenter", () => {
          tooltipEl.classList.remove("opacity-0", "invisible");
          tooltipEl.classList.add("opacity-100", "visible");
        });

        newTriggerEl.addEventListener("mouseleave", () => {
          tooltipEl.classList.remove("opacity-100", "visible");
          tooltipEl.classList.add("opacity-0", "invisible");
        });
      }
    });
  }
}

// Remove unused monitorWebSocketState function
const monitorWebSocketState = (wsElement) => {
  if (!wsElement?.webSocket) return;

  const ws = wsElement.webSocket;
  const stateMap = {
    0: 'CONNECTING',
    1: 'OPEN',
    2: 'CLOSING',
    3: 'CLOSED'
  };
};

/**
 * Handle WebSocket message submission
 * @event htmx:wsConfigSend
 * @listens document.body#htmx:wsConfigSend
 * @param {Event} wsConfigEvent - HTMX WebSocket config send event
 */
document.body.addEventListener(
  "htmx:wsConfigSend",
  throttle(function (wsConfigEvent) {
    try {
      const userInputField = document.getElementById("user-input");
      const submitButton = document.querySelector(
        '#chat-form button[type="submit"]',
      );
      const userMessage = userInputField?.value.trim() ?? "";

      const temperatureInput = document.getElementById("temperature-input");
      const modelInput = document.getElementById("model-input");

      if (
        !userInputField ||
        !submitButton ||
        !temperatureInput ||
        !modelInput
      ) {
        handleError(
          "locating form elements",
          "Required form elements not found",
          "ELEMENT_NOT_FOUND",
        );
        return;
      }

      // Get temperature
      let temperature;
      try {
        temperature = parseFloat(temperatureInput.value);
        if (isNaN(temperature)) {
          throw new Error("Invalid temperature value");
        }
      } catch (error) {
        handleError("parsing temperature", error, "FORM_VALIDATION");
        return;
      }

      // Update parameters with temperature
      wsConfigEvent.detail.parameters.temperature = temperature;
      wsConfigEvent.detail.parameters.model = modelInput.value;

      // Add full config to message body
      wsConfigEvent.detail.parameters.full_config = {
        model: modelInput.value,
        temperature: temperature,
        timestamp: new Date().toISOString(),
        provider: modelInput.value.split("/")[0],
      };

      if (userMessage) {
        const chatMessages = Alpine.store("chatMessages");
        if (!chatMessages) {
          handleError(
            "accessing chat messages store",
            "Chat messages store not initialized",
            "CHAT_STORE",
          );
          return;
        }

        // Add user message using Alpine store
        chatMessages.addUserMessage(userMessage);

        // Clear input and disable button
        userInputField.value = "";
        userInputField.style.height = "auto";
        submitButton.disabled = true;
      }
    } catch (error) {
      handleError(
        "handling WebSocket message submission",
        error,
        "MESSAGE_SUBMISSION",
      );
    }
  }, 200),
);

// ===================================================
// ALPINE.JS INITIALIZATION
// ===================================================
document.addEventListener("alpine:init", () => {
  /**
   * Main chat initialization component
   * @function chatInit
   * @param {number} initialTemp - Initial temperature value
   * @param {boolean} isNewChat - Whether this is a new chat session
   * @returns {Object} Alpine.js component properties
   */
  Alpine.data("chatInit", (initialTemp, isNewChat) => ({
    init() {
      this.initializeChatData(initialTemp, isNewChat);
      initTooltips();
    },

    /**
     * Initialize chat data and state
     * @method initializeChatData
     * @param {number} temperature - Initial temperature value
     * @param {boolean} isNewChat - Whether this is a new chat
     */
    initializeChatData(temperature, isNewChat) {
      const { chatState, chatMessages } = this.$store;

      // Get temperature limits using our helper
      const limits = TemperatureHelper.getTemperatureLimits();

      // Initialize temperature from passed value and clamp to valid model range
      chatState.temperature = TemperatureHelper.clampTemperature(
        temperature,
        limits.min,
        limits.max,
      );

      // Force balanced mode for new chats if available
      if (isNewChat) {
        const balancedTemp = limits.default;
        if (balancedTemp >= limits.min && balancedTemp <= limits.max) {
          chatState.temperature = balancedTemp;
        } else {
          // Use middle of available range if balanced is not available
          chatState.temperature = (limits.min + limits.max) / 2;
        }
      }

      // Initialize chat UUID from URL if available
      if (!chatState.chatUuid) {
        const pathSegments = window.location.pathname.split("/");
        const chatUuid = pathSegments[pathSegments.length - 2]; // UUID is second to last segment

        if (chatUuid && chatUuid !== "chat") {
          chatState.chatUuid = chatUuid;

          // Update any chat_uuid inputs with the UUID from URL
          document
            .querySelectorAll('input[name="chat_uuid"]')
            .forEach((input) => {
              input.value = chatState.chatUuid;
            });
        }
      }

      // Initialize stores
      this.$nextTick(() => {
        if (chatState && chatMessages) {
          chatState.initializeChatState();
          this.isLoaded = true;
        }
      });
    },

    isLoaded: false,
  }));

  // ===================================================
  // ALPINE.JS STORES
  // ===================================================
  /**
   * Alpine.js store for managing chat messages and processing state
   * @namespace Alpine.store.chatMessages
   * @property {Array<Object>} messages - Array of chat messages
   * @property {boolean} isProcessing - Whether a message is currently being processed/generated
   * @property {string} currentResponse - The current streaming AI response text
   */
  Alpine.store("chatMessages", {
    messages: [],
    isProcessing: false,
    currentResponse: "",



    /**
     * Add user message to the message store
     * @method addUserMessage
     * @param {string} content - Message content to add
     * @returns {void} This method doesn't return a value
     * @description Adds a user message to the chat message store, renders markdown content,
     *              and notifies listeners about the updated messages.
     */
    addUserMessage(content) {
      if (!content.trim()) return;
      try {
        // Sanitize the markdown content first
        const sanitized = TextFormatHelper.sanitizeMarkdown(content);
        const rendered = renderMarkdownWithHighlighting(sanitized);
        this.messages.push({
          type: MESSAGE_TYPES.USER,
          content: rendered,
          rawContent: sanitized, // Store sanitized markdown
          timestamp: new Date().toISOString(),
        });
        notifyMessagesUpdated();
      } catch (error) {
        handleError("adding user message", error, "CHAT_STORE");
      }
    },

    /**
     * Update AI response in the message store
     * @method updateAIResponse
     * @param {string} content - Response content from the AI
     * @param {boolean} [isComplete=false] - Whether the response is complete or still streaming
     * @returns {void} This method doesn't return a value
     * @description Handles streaming and completed AI responses differently:
     *              - For complete responses: Adds a new message to the store
     *              - For streaming: Updates the current response in-place
     *              Updates accessibility status and notifies listeners about changes.
     */
    updateAIResponse(content, isComplete = false) {
      try {
        this._updateAccessibilityStatus(isComplete);

        // Sanitize the content first
        const sanitized = TextFormatHelper.sanitizeMarkdown(content);

        if (isComplete) {
          this._handleCompleteResponse(sanitized);
        } else {
          this._handleStreamingResponse(sanitized);
        }
      } catch (error) {
        this._handleResponseError(error, content, isComplete);
      }
    },

    /**
     * Update accessibility status for AI responses
     * @method _updateAccessibilityStatus
     * @param {boolean} isComplete - Whether the response is complete
     * @returns {void} This method doesn't return a value
     * @private
     */
    _updateAccessibilityStatus(isComplete) {
      const chatBox = document.getElementById("chat-box");
      if (isComplete) {
        chatBox.setAttribute("aria-busy", "false");
        updateA11yStatus("Response complete");
      } else {
        chatBox.setAttribute("aria-busy", "true");
        updateA11yStatus("Receiving response");
      }
    },

    /**
     * Handle a complete AI response
     * @method _handleCompleteResponse
     * @param {string} content - Response content from the AI
     * @returns {void} This method doesn't return a value
     * @private
     */
    _handleCompleteResponse(content) {
      // Apply sanitization before storing or rendering
      const sanitizedContent = TextFormatHelper.sanitizeMarkdown(content);
      const rendered = renderMarkdownWithHighlighting(sanitizedContent, false);
      this.messages.push({
        type: MESSAGE_TYPES.AI,
        content: rendered,
        rawContent: sanitizedContent, // Store sanitized markdown
        timestamp: new Date().toISOString(),
      });
      this.currentResponse = "";
      this.isProcessing = false;
      notifyMessagesUpdated();
    },

    /**
     * Handle a streaming AI response
     * @method _handleStreamingResponse
     * @param {string} content - Response content from the AI
     * @returns {void} This method doesn't return a value
     * @private
     */
    _handleStreamingResponse(content) {
      // Apply sanitization before storing or rendering
      const sanitizedContent = TextFormatHelper.sanitizeMarkdown(content);
      this.currentResponse = sanitizedContent;
      const rendered = renderMarkdownWithHighlighting(sanitizedContent, true);
      this._updateStreamingContent(rendered);

      // Add direct scroll trigger for streaming updates
      setTimeout(() => {
        const chatBox = document.getElementById("chat-box");
        if (chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      }, 50);

      notifyMessagesUpdated();
    },

    /**
     * Update the DOM content for streaming responses
     * @method _updateStreamingContent
     * @param {string} renderedContent - HTML content to update in the DOM
     * @returns {void} This method doesn't return a value
     * @private
     */
    _updateStreamingContent(renderedContent) {
      const chatBox = document.getElementById("chat-box");
      if (chatBox && chatBox.lastElementChild) {
        const lastMessage = chatBox.lastElementChild.querySelector(".prose");
        if (lastMessage) {
          lastMessage.innerHTML = renderedContent;
        }
      }
    },

    /**
     * Handle errors that occur during response processing
     * @method _handleResponseError
     * @param {Error} error - The error object
     * @param {string} content - Original response content
     * @param {boolean} isComplete - Whether the response was complete
     * @returns {void} This method doesn't return a value
     * @private
     */
    _handleResponseError(error, content, isComplete) {
      updateA11yStatus("Error occurred while updating response");
      handleError("updating AI response", error, "CHAT_STORE");
      if (isComplete) {
        // Sanitize content even in error case
        const sanitized = TextFormatHelper.escapeHtmlCharacters(content);
        this.messages.push({
          type: MESSAGE_TYPES.AI,
          content: sanitized,
          rawContent: sanitized, // Store sanitized content
          timestamp: new Date().toISOString(),
        });
        this.currentResponse = "";
        this.isProcessing = false;
        notifyMessagesUpdated();
      }
    },

    /**
     * Copy message content to clipboard using modern Clipboard API
     * @method copyMessage
     * @param {number} index - Index of the message to copy
     * @returns {Promise<boolean>} - Whether the copy was successful
     */
    async copyMessage(index) {
      try {
        const message = this.messages[index];
        if (!message) return false;

        // Use rawContent which now contains unescaped text
        const content = message.rawContent || "";
        if (!content) return false;

        // Copy the unescaped content to clipboard
        await navigator.clipboard.writeText(content);
        updateA11yStatus("Message copied to clipboard", true);
        return true;
      } catch (error) {
        console.error("Error copying message:", error);
        updateA11yStatus("Failed to copy - browser permission required", true);
        return false;
      }
    },

    /**
     * Handle error messages in the chat interface
     * @method handleError
     * @param {string|Object} error - Error message or error object to display
     * @returns {void} This method doesn't return a value
     * @description Adds an error message to the message store, stops any ongoing processing,
     *              and notifies listeners about the updated messages.
     */
    handleError(error) {
      // Normalize the error object
      const errorObj = typeof error === "object" ? error : { message: error };
      const errorMessage = errorObj.message || "An unknown error occurred";
      const errorCode = errorObj.code || ChatErrorTypes.UNKNOWN_ERROR.code;

      // Add to messages with enhanced error information
      this.messages.push({
        type: MESSAGE_TYPES.ERROR,
        content: `Error ${errorCode}: ${errorMessage}`,
        details: errorObj.details || {},
        timestamp: new Date().toISOString(),
        code: errorCode,
        recoverable:
          errorObj.recoverable !== undefined ? errorObj.recoverable : false,
      });

      this.isProcessing = false;
      notifyMessagesUpdated();

      // Update screen reader status with error info
      updateA11yStatus(`Error ${errorCode}: ${errorMessage}`);

      // Log to console for debugging
      console.error(`Chat error ${errorCode}:`, errorObj);

      // Attempt recovery for recoverable errors
      if (errorObj.recoverable) {
        setTimeout(() => this.attemptRecovery(errorObj), 3000);
      }
    },

    /**
     * Unified error recovery system that handles both technical recovery and user feedback
     * @method attemptRecovery
     * @param {Object|string} errorTypeOrObject - Error object or error type string
     * @returns {void} This method doesn't return a value
     */
    attemptRecovery(errorTypeOrObject) {
      // Normalize input to get error info
      const errorObj =
        typeof errorTypeOrObject === "string"
          ? {
              code: ChatErrorTypes[errorTypeOrObject]?.code,
              message: ChatErrorTypes[errorTypeOrObject]?.message,
            }
          : errorTypeOrObject;

      const errorCode = errorObj.code || ChatErrorTypes.UNKNOWN_ERROR.code;
      const errorType =
        Object.keys(ChatErrorTypes).find(
          (key) => ChatErrorTypes[key].code === errorCode,
        ) || "UNKNOWN_ERROR";

      console.log(`Attempting recovery for error ${errorCode} (${errorType})`);

      // Add recovery message to UI
      this.messages.push({
        type: MESSAGE_TYPES.SYSTEM,
        content: "Attempting to recover from previous error...",
        timestamp: new Date().toISOString(),
        details: {
          code: errorCode,
          originalMessage: errorObj.message,
          recoveryType: errorType,
        },
      });

      notifyMessagesUpdated();

      // Technical recovery based on error type
      switch (errorType) {
        case "WEBSOCKET_CONNECTION":
        case "WEBSOCKET_MESSAGE":
          // Try to reconnect WebSocket after a short delay
          setTimeout(() => {
            console.log("Attempting to reconnect WebSocket...");
            // Trigger reconnection via HTMX
            const form = document.getElementById("chat-form");
            if (form) {
              htmx.trigger(form, "reconnect", {});
            }
          }, 2000);
          break;

        case "CHAT_STORE":
        case "CHAT_STATE":
          // Try to reinitialize the store
          try {
            const store = Alpine.store(
              errorType === "CHAT_STORE" ? "chatMessages" : "chatState",
            );
            if (store && typeof store.init === "function") {
              console.log(`Reinitializing ${errorType} store...`);
              store.init();
            }
          } catch (recoveryError) {
            console.error(`Recovery failed for ${errorType}:`, recoveryError);
          }
          break;

        case "MESSAGE_SUBMISSION":
        case "FORM_VALIDATION":
          // Enable submit button if it was disabled
          try {
            const submitButton = document.querySelector(
              '#chat-form button[type="submit"]',
            );
            if (submitButton && submitButton.disabled) {
              submitButton.disabled = false;
              console.log("Re-enabling submit button after error");
            }
          } catch (recoveryError) {
            console.error("Failed to re-enable submit button:", recoveryError);
          }
          break;

        case "SERVER_ERROR":
        case "PROVIDER_ERROR":
        case "MODEL_ERROR":
          // For server-side errors, we might want to suggest a model change
          this.messages.push({
            type: MESSAGE_TYPES.SYSTEM,
            content:
              "You might want to try a different model or try again later.",
            timestamp: new Date().toISOString(),
          });
          notifyMessagesUpdated();
          break;

        default:
          // No specific recovery mechanism for this error type
          console.log(`No specific recovery mechanism for ${errorType} errors`);
      }
    },

  });

  /**
   * Alpine.js store for managing chat UI state
   * @namespace Alpine.store.chatState
   * @property {boolean} hasMessages - Whether there are any messages in the chat
   * @property {string} username - Username of the current user, from window.chatUsername
   * @property {number} currentHour - Current hour of the day (0-23)
   * @property {number} temperature - LLM temperature setting for the chat
   * @property {string} chatTitle - Current chat title
   * @property {string} chatUuid - UUID of the current chat session
   */
  Alpine.store("chatState", {
    hasMessages: false,
    username: username,
    currentHour: new Date().getHours(),
    temperature: window.TEMPERATURE_SETTINGS?.DEFAULT || 0.6, // Now uses window value
    chatTitle: null, // Store the chat title
    chatUuid: null, // Store the chat UUID

    /**
     * Initialize the chat state store on Alpine.js initialization
     * @method init
     * @returns {void} This method doesn't return a value
     */
    init() {
      this.initializeChatState();
    },

    /**
     * Initialize the chat state from the Alpine store
     * @method initializeChatState
     * @returns {void} This method doesn't return a value
     * @description Retrieves the chat messages from the Alpine store and
     *              sets the hasMessages property based on whether there are
     *              existing messages. Provides error handling if the store
     *              cannot be accessed.
     */
    initializeChatState() {
      try {
        const chatMessages = Alpine.store("chatMessages");
        if (chatMessages) {
          this.hasMessages = chatMessages.messages.length > 0;
        }

        // Initialize chat title from the DOM if available
        const chatTitleElement = document.getElementById("chat-title");
        if (chatTitleElement && chatTitleElement.textContent.trim()) {
          this.chatTitle = chatTitleElement.textContent.trim();
        }

        // Initialize chat UUID from the URL if available
        const pathSegments = window.location.pathname.split("/");
        const chatUuid = pathSegments[pathSegments.length - 2]; // UUID is second to last segment
        if (chatUuid && chatUuid !== "chat") {
          this.chatUuid = chatUuid;
        }
      } catch (error) {
        console.error("Error initializing chat state:", error);
        this.hasMessages = false;
      }
    },

    /**
     * Get time-appropriate greeting text based on current time of day
     * @method getGreetingText
     * @returns {string} Greeting message appropriate for the current time
     * @description Returns a greeting based on the current hour:
     *              - "Late night" (12am-5am)
     *              - "Good morning" (5am-12pm)
     *              - "Good afternoon" (12pm-6pm)
     *              - "Good evening" (6pm-12am)
     *              Appends the username if available, otherwise uses "there" as a fallback.
     */
    get getGreetingText() {
      let greeting;
      if (this.currentHour < 5) {
        greeting = "Late night";
      } else if (this.currentHour < 12) {
        greeting = "Good morning";
      } else if (this.currentHour < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }
      return `${greeting}, ${this.username || "there"}!`;
    },
  });

  // ===================================================
  // ALPINE.JS COMPONENTS
  // ===================================================
  /**
   * Alpine.js component for chat input handling
   * @namespace Alpine.data.chatInput
   * @property {string} message - The current user input message text
   * @property {Array<number>} temperatures - Available temperature settings for the model
   * @property {Object.<number, string>} temperatureLabels - Display labels for temperature values
   * @property {Object.<number, string>} temperatureTooltips - Tooltip descriptions for temperature values
   * @property {Function} resize - Resize textarea to fit content
   * @property {Function} handleKeydown - Handle keyboard events in textarea
   * @property {Function} handleSubmit - Handle form submission
   * @property {Function} getTemperatureLabel - Get temperature mode label
   * @property {Function} getTemperatureTooltip - Get temperature mode tooltip
   */
  Alpine.data("chatInput", () => ({
    message: "",
    temperatures: [],
    temperatureLabels: {},
    temperatureTooltips: {},

    /**
     * Alpine.js lifecycle initialization hook
     * @method init
     */
    init() {
      this.initializeChatInput();
    },

    /**
     * Initialize chat input component
     * @method initializeChatInput
     */
    initializeChatInput() {
      // Use available presets from window or parse from data attributes
      const container = document.querySelector(".temperature-controls");
      if (container) {
        try {
          const presets = JSON.parse(container.dataset.temperatureValues);
          // Extract values from preset objects
          this.temperatures = presets.map((preset) => preset.value);

          // Create lookup objects for labels and tooltips
          this.temperatureLabels = Object.fromEntries(
            presets.map((preset) => [preset.value, preset.label]),
          );
          this.temperatureTooltips = Object.fromEntries(
            presets.map((preset) => [preset.value, preset.tooltip]),
          );
        } catch (e) {
          console.error("Error parsing temperature settings:", e);
          // No fallbacks - we'll have empty controls if parsing fails
        }
      }

      // Initialize the component
      this.temperature = Alpine.store("chatState").temperature;
    },

    /**
     * Resize textarea to fit content dynamically
     * @method resize
     * @returns {void} This method doesn't return a value
     */
    resize() {
      // Avoid using debounce for this critical function
      try {
        /** @type {TextareaElement} */
        const textareaElement = this.$refs.textarea;
        if (!textareaElement) return;

        // Reset height to auto to get correct scrollHeight
        textareaElement.style.height = "0px";
        // Set height to scrollHeight to fit content, respecting max-height
        textareaElement.style.height =
          Math.min(
            textareaElement.scrollHeight,
            parseInt(getComputedStyle(textareaElement).maxHeight || "1000"),
          ) + "px";
      } catch (error) {
        handleError("Chat input resize", error, "ELEMENT_NOT_FOUND");
      }
    },

    /**
     * Handle keyboard events in textarea for submitting messages and adding newlines
     * @method handleKeydown
     * @param {KeyboardEvent} keyboardEvent - The keyboard event object
     * @returns {void} This method doesn't return a value
     * @description Handles keyboard events in the textarea to submit messages and add newlines.
     *              If the Enter key is pressed without the Shift key, the message is submitted.
     *              If the Enter key is pressed with the Shift key, a newline is added to the message.
     */
    handleKeydown(keyboardEvent) {
      // Check if the key pressed is 'Enter'
      if (keyboardEvent.key === "Enter") {
        try {
          // If Shift key is not pressed and the message is not empty
          if (!keyboardEvent.shiftKey) {
            // Prevent the default Enter key behavior (adding a new line)
            keyboardEvent.preventDefault();

            // Get the message from the textarea
            const message = this.message.trim();
            if (message) {
              // Submit the message through HTMX's WebSocket
              const { form } = this.$refs;
              if (form) {
                htmx.trigger(form, "submit");
              }
            }
          } else {
            // Shift+Enter adds a new line
            keyboardEvent.preventDefault();
            const cursorStartPosition = this.$refs.textarea.selectionStart;
            const cursorEndPosition = this.$refs.textarea.selectionEnd;
            this.message =
              this.message.substring(0, cursorStartPosition) +
              "\n" +
              this.message.substring(cursorEndPosition);
            this.$nextTick(() => {
              this.$refs.textarea.selectionStart =
                this.$refs.textarea.selectionEnd = cursorStartPosition + 1;
              this.resize();
            });
          }
        } catch (error) {
          handleError("handling keydown event", error, "FORM_VALIDATION");
        }
      }
    },

    /**
     * Handle form submission for chat messages
     * @method handleSubmit
     * @returns {void} This method doesn't return a value
     * @description Processes the submission of a chat message by:
     *              - Validating that the message is not empty
     *              - Finding required form elements (input field, submit button, etc.)
     *              - Preventing multiple submissions by disabling the submit button
     *              - Using native form submission to avoid recursive calls
     *              - Clearing the input field and resetting the Alpine.js message property
     */
    handleSubmit() {
      if (!this._validateMessage()) return;

      const formElements = this._getFormElements();
      if (!formElements) return;

      const { form, userInputField, submitButton } = formElements;

      if (!form || !userInputField || !submitButton) {
        throw new Error("Required form elements missing for submission");
      }

      // Show loading indicator immediately
      Alpine.store("chatMessages").isProcessing = true;

      // Disable submit button to prevent multiple submissions
      submitButton.disabled = true;

      // Update ARIA attributes for accessibility
      userInputField.setAttribute("aria-disabled", "true");

      // Store message before clearing
      const { message } = this;

      // Use native form submission instead of triggering another event
      // This avoids the recursive call that causes the stack overflow
      if (form) {
        // Let HTMX's ws-send handle the form naturally
        // The @submit.prevent in the template already prevents default behavior
      }

      // Delay reset until after HTMX processes the event
      setTimeout(() => {
        // Reset form state
        this._resetFormState(userInputField);

        // Notify screen readers
        updateA11yStatus(
          `Message "${message.substring(0, 20)}${message.length > 20 ? "..." : ""}" sent`,
        );
      }, 0);
    },

    /**
     * Validate that the message is not empty
     * @method _validateMessage
     * @returns {boolean} True if the message is valid, false otherwise
     * @private
     */
    _validateMessage() {
      try {
        if (!this.message) {
          return false;
        }

        // Check if the message contains only whitespace
        if (this.message.trim().length === 0) {
          return false;
        }

        return true;
      } catch (error) {
        handleError("validating message", error, "FORM_VALIDATION");
        return false;
      }
    },

    /**
     * Get all required form elements with caching for better performance
     * @method _getFormElements
     * @returns {Object|null} Object with form elements or null if any required element is missing
     * @private
     */
    _getFormElements() {
      try {
        const { form } = this.$refs;
        if (!form) {
          handleError(
            "getting form element",
            "Form element not found in refs",
            "ELEMENT_NOT_FOUND",
          );
          return null;
        }

        // Get required elements
        const userInputField = form.querySelector("#user-input");
        const submitButton = form.querySelector('button[type="submit"]');
        const temperatureInput = form.querySelector("#temperature-input");
        const modelInput = form.querySelector("#model-input");

        if (
          !userInputField ||
          !submitButton ||
          !temperatureInput ||
          !modelInput
        ) {
          handleError(
            "getting form elements",
            "Required form elements not found",
            "ELEMENT_NOT_FOUND",
          );
          return null;
        }

        return {
          form,
          userInputField,
          submitButton,
          temperatureInput,
          modelInput,
        };
      } catch (error) {
        handleError("getting form elements", error, "ELEMENT_NOT_FOUND");
        return null;
      }
    },

    /**
     * Reset form state after submission
     * @method _resetFormState
     * @param {HTMLElement} userInputField - The textarea element
     * @returns {void} This method doesn't return a value
     * @private
     */
    /**
     * Reset form state after submission
     * @method _resetFormState
     * @param {TextareaElement} userInputField - The textarea element
     * @returns {void} This method doesn't return a value
     * @private
     */
    _resetFormState(userInputField) {
      // Clear input and reset height
      userInputField.value = "";
      userInputField.style.height = "auto";

      // Clear the Alpine model
      this.message = "";
    },

    /**
     * Get temperature mode label
     * @method getTemperatureLabel
     * @param {number} temperature - Temperature value
     * @returns {string} Label for temperature mode
     */
    getTemperatureLabel(temperature) {
      return TemperatureHelper.getLabel(temperature, this.temperatureLabels);
    },

    /**
     * Get temperature mode tooltip
     * @method getTemperatureTooltip
     * @param {number} temperature - Temperature value
     * @returns {string} Tooltip text for temperature mode
     */
    getTemperatureTooltip(temperature) {
      return TemperatureHelper.getTooltip(
        temperature,
        this.temperatureTooltips,
      );
    },
  }));
});

// ===================================================
// TEXT FORMATTING HELPERS
// ===================================================
/**
 * Text formatting helper functions
 * @namespace TextFormatHelper
 */
const TextFormatHelper = {
  /**
   * Convert HTML to markdown by replacing HTML tags with their markdown equivalents
   * @function convertHtmlToMarkdown
   * @param {string} html - HTML content to convert
   * @returns {string} Converted markdown content
   */

  /**
   * Sanitize markdown content by escaping HTML outside code blocks
   * @function sanitizeMarkdown
   * @param {string} content - Raw markdown input
   * @returns {string} Sanitized markdown with HTML escaped outside code blocks
   */
  sanitizeMarkdown: memoize(
    function sanitizeMarkdown(content) {
      if (!content) return "";

      try {
        // Split content while preserving code blocks
        const blocks = content.split(/(```[\s\S]*?```|`[^`]+`)/g);

        return blocks
          .map((block, index) => {
            // Return code blocks verbatim
            if (index % 2 === 1) return block;

            // For regular text - no HTML escaping since markdown-it handles it
            return block;
          })
          .join("");
      } catch (error) {
        console.error("Markdown sanitization error:", error);
        // Fallback to full escape if parsing fails
        return TextFormatHelper.escapeHtmlCharacters(content);
      }
    },
    { maxSize: 100, ttl: 300000 }, // 100 entries, 5 minute TTL
  ),

  /**
   * Escape HTML special characters to prevent XSS attacks
   * @function escapeHtmlCharacters
   * @param {string} text - Text to escape
   * @returns {string} Text with HTML special characters escaped
   */
  escapeHtmlCharacters: memoize(
    function escapeHtmlCharacters(text) {
      try {
        if (!text || typeof text !== "string") {
          return "";
        }

        return text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;");
      } catch (error) {
        console.error("Error escaping HTML characters:", error);
        return text || ""; // Return original text or empty string as fallback
      }
    },
    { maxSize: 100, ttl: 600000 },
  ), // 100 entries, 10 minute TTL
};

// Export for potential future use
window.TextFormatHelper = TextFormatHelper;
